import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SimulationsPage from "../../components/services/simulations/index"
const simulations = () => {
  return (
    <Layout>
      <SEO
        title="Simulation in India - Innoway Systems"
        desc="Computational fluid dynamics (CFD) simulation allows you to predict, with confidence, the impact of fluid flows on your product throughout design and manufacturing as well as during end use."
        pathname="/services/simulations/"
        keywords="Simulation in coimbatore,Simulation in TamilNadu"
      />
      <SimulationsPage />
    </Layout>
  )
}

export default simulations
