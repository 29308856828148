import React from "react"
import { Link } from "gatsby"

const simulations = () => {
    return (
        <>
            <section>
                <div className="parallax">
                    <img src="/img/black-illustration-on-white-printer-paper-716661.jpg" alt="" />
                    <div className="overlay"></div>
                    <div className="headercontent">
                        <h1>SIMULATIONS</h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="container pt-4">
                    <p>Computational fluid dynamics (CFD) simulation allows you to predict, with confidence, the impact of fluid flows on your product throughout design and manufacturing as well as during end use. The analysis can be used to design and optimize new part and to troubleshoot an existing part. In general fluid dynamics solutions give you valuable insight into your product’s performance.</p>
                    <div className="row">
                        <div className="col-12 col-lg-6 text-center">
                            <img src="/img/s1.png" className="img-fluid" alt="Products" ></img>
                        </div>
                        <div className="col-12 col-lg-6 text-left">
                            <img src="/img/s2.png" className="img-fluid" alt="Products"></img>
                        </div>
                    </div>
                    <div className="text-center">
                        <p>Fluid dynamics plays a critical role in many of the products – from many applications such as automobile, aircraft aero dynamism, HVAC systems, Thermal protection equipment, plastic and glass materials. Fluid dynamics is involved in the design and manufacture of numerous of consumer products, industrial and military products. In applications that involve gas flow, liquid flow or heat transfer, fluid dynamics analysis can help deliver innovation and greater efficiency.</p>
                        <p>Product design and analysis is done in a virtual environment By identifying physical forces and flow characteristics that are sometimes impossible to measure or gain insight into, CFD solutions can help your company dramatically improve time to market, cut down development costs and reduce wastage, minimizing warranty expenses helps you fulfill your critical product promises —and driving higher customer satisfaction.</p>
                    </div>
                    <div className="row">
                        <div className="preview col-12 col-lg-4">
                            <img src="/img/s3.png" className="img-fluid" alt="Products"></img>
                        </div>
                        <div className="details col-12 col-lg-8">
                            <p>Innoway Systems has established the CFD capability to support the customer requirements, there by Innoway has become one stop solution for design and development. <Link to="/contactus/">Contact our customer carefor </Link> more information.</p><br />
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/contactus/" className="btn btn-danger">Contact Now</Link><br /> <br />
                    </div>
                </div>
            </section>
            <section className="elementor-element elementor-element-47678ea elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                        <div className="elementor-element elementor-element-b103d23 elementor-column elementor-col-33 elementor-top-column">
                            <div className="elementor-column-wrap">
                                <div className="elementor-widget-wrap">
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-398c959 elementor-column elementor-col-33 elementor-top-column">
                            <div className="elementor-column-wrap">
                                <div className="elementor-widget-wrap">
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-7f87b5e elementor-column elementor-col-33 elementor-top-column">
                            <div className="elementor-column-wrap">
                                <div className="elementor-widget-wrap">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default simulations
